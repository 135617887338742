<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="vx-col">
    <div class="vx-row">
  <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>

      <h2 style="color: #0082d5" class="mb-2">
        {{ $t("AddHospitalSubSpecialities") }}
      </h2>
    </div>
    <div class="vx-row ml-3">
      <h2>{{ $t("FilterBySpecialty") }}</h2>
    </div>

    <div class="vx-row mt-3">
      <div
        
        v-for="item in HospitalParentSpecialties"
        :key="item.ID"
       

      >
        <div class="">
          <vs-button
            size="large"
            style="border-radius: 34px; opacity: 1"
            :color="
              selectedSpecialityFilter.ID == item.ID ? '#004477' : 'white'
            "
            :style="[
              selectedSpecialityFilter.ID == item.ID
                ? {}
                : { border: ' 2px solid #1F1F1F' },
            ]"
            @click="select(item)"
            class="m-1"
            ><span
              :style="[
                selectedSpecialityFilter.ID == item.ID
                  ? { color: 'white' }
                  : { color: 'black' },
              ]"
            >
              {{ item.Specialty.Name }}</span
            >
          </vs-button>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col lg:w-1/5 w-full w-full mt-5"></div>
      <div class="vx-col lg:w-1/3 w-full w-full mt-5">
        <h2 style="margin-left: -30%" class="mb-5">
          {{ $t("AddSubSpeciality") }}
        </h2>
         <span class="warning">  {{$t("saveNote")}}</span>
        <vs-card class="vx-col mt-1" style="height: 50vh; overflow-y: scroll">
          <h6 style="color:red" class="mb-5">
          {{ $t("PleaseSelectOnOneOfSpecialities") }}
        </h6>
          <vs-input
            v-model="search.SpecialityName"
            :placeholder="$t('Search')"
            class="w-full"
            name="search"
          />

          <div
            v-for="item in filterSpeciality"
            :key="item.ID"
            :class="item.IsSelected ? 'Active' : 'InActive'"
            class="shadow-md w-full mt-2"
          >
            <p class="m-5 p-3 vx-row">
              <vs-checkbox
                v-model="item.IsSelected"
                @input="SpecialityChecked(item)"
                :disabled="(item.CantChecked==true)"

              ></vs-checkbox>
              {{ item.Name }}
            </p>
          </div>
        </vs-card>
      </div>

      <div class="vx-col lg:w-1/3 w-full w-full" style="margin-top: 5%">
      
        <vs-card class="vx-col mt-8" style="height: 50vh; overflow-y: scroll">
          <h2 class="m-1 Active">{{ $t("SubSpecialities") }}</h2>

          <div
            v-for="item in HospitalChiledSpecialties"
            :key="item.ID"
            class="InActive shadow-md w-full mt-2"
            v-if="item.IsActive!=false"
          >
            <li style="margin: 5%; padding: 3%; font-size: medium">
              {{ item.Specialty.Name }}
            </li>
          </div>
        </vs-card>
        <div class="vx-row m-10">
          <div class="lg:w-1/3 mt-3" style="color: #004477">
            <u @click="SaveSpecialities()" style="font-size: 20px">{{
              $t("Save")
            }}</u>
          </div>
          <div class="lg:w-2/3 w-full">
            <vs-button
              color="#004477"
              @click="AddTreatment()"
              class="vx-row mt-3 customizer-btn text-sm text-white"
              ><h6 style="color: white">{{ $t("AddTreatments") }}</h6>
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import LoginFirebase from "./LoginFirebase.vue"
//import LoginAuth0 from "./LoginAuth0.vue"
import axios from "@/axios.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";

import { domain, mainSetting } from "@/gloabelConstant.js";
export default {
  data() {
    return {
      EnglishLan:false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      Model: {},
      search: {},
      HospitalChiledSpecialties: [],
      HospitalParentSpecialties: [],

      selectedSpecialityFilter: { ID: 0 },
    };
  },
  computed: {
    parentSpecialties() {
      return this.$store.state.SpecialtyList.parentSpecialties;
    },
    childSpecialties() {
      return this.$store.state.SpecialtyList.childSpecialties;
    },
    filterSpeciality() {
      if (this.search.SpecialityName) {
        var vm = this;
        return this.childSpecialties.filter(function (obj) {
          return (
            obj.Name.toLowerCase().indexOf(
              vm.search.SpecialityName.toLowerCase()
            ) > -1
          );
        });
      } else return this.childSpecialties;
    },
  },
  methods: {
    select(item) {
      debugger;
      this.selectedSpecialityFilter = item;
      this.ChangeParentSpeciality(item);
      this.GetChiledSpecialities(item);
    },
    GetChiledSpecialities(item) {
      var data = {};
      data.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
      data.SpecialityID = item.SpecialtyID;
      this.$store
        .dispatch("HospitalList/GetHospitalChiledSpecialty", data)
        .then((res) => {
          this.HospitalChiledSpecialties= [];
          this.HospitalChiledSpecialties = res.data.Data;
          this.HospitalChiledSpecialties.forEach((element) => {
            debugger;
            var index = this.childSpecialties.findIndex(
              (x) => x.ID == element.SpecialtyID
            );
            this.childSpecialties[index].IsSelected = true;
             var index2 = this.childSpecialties.findIndex(
              (x) => (x.ID == element.SpecialtyID &&element.IsActive==false)
            );
            if (index2 != -1) {
              this.childSpecialties[index2].CantChecked = true;
            }
          });
        });
    },
    ChangeParentSpeciality(item) {
      this.$store.dispatch(
        "SpecialtyList/GetSpecialtyByParentSpecialtyID",
        item.SpecialtyID
      );
    },
    AddTreatment() {
      this.$router.push("/hospital/AddTreatmentSurgery");
    },
    back() {
      this.$router.go(-1);
    },
    SpecialityChecked(item) {
      debugger;
      if (item.IsSelected == true) {
        this.AddHospitalSpeciality(item);
      } else {
        this.DeleteHospitalSpeciality(item);
      }
    },
    DeleteHospitalSpeciality(item) {
      var index = this.HospitalChiledSpecialties.findIndex(
        (x) => x.SpecialtyID == item.ID
      );
      this.HospitalChiledSpecialties.splice(index, 1);
    },
    AddHospitalSpeciality(item) {
      var index = this.HospitalChiledSpecialties.findIndex(
        (x) => x.SpecialtyID == item.ID
      );
      if (index >= 0) {
        //alert
      } else {
        var hospitalParent = {};
        hospitalParent.Specialty = {};
        hospitalParent.Specialty.Name = item.Name;
        hospitalParent.IsActive =  true;
        hospitalParent.Specialty.SpecialtyID = item.ID;
        hospitalParent.Specialty.HospitalID =
          this.$store.state.AppActiveUser.Hospital.ID;
        hospitalParent.SpecialtyID = item.ID;
        hospitalParent.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        this.HospitalChiledSpecialties.push(hospitalParent);
      }
    },
    SaveSpecialities() {
      this.$vs.loading();

      debugger;
      if (this.HospitalChiledSpecialties.length > 0) {
        this.$store
          .dispatch(
            "HospitalList/SaveChiledSpecialities",
            this.HospitalChiledSpecialties
          )
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
              //  this.back();
            }
            this.$vs.loading.close();
          });
      } else {
        var data = {};
        data.hospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        data.specialityID = this.selectedSpecialityFilter.SpecialtyID;
        this.$store
          .dispatch("HospitalList/DeleteOldChiledSpecialities", data)
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
              //  this.back();
            }
            this.$vs.loading.close();
          });
      }
    },
  },
  created() {
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    this.$store.commit("SpecialtyList/SET_ChildSpecialty", []);

    this.$store.dispatch("SpecialtyList/GetParentSpecialty");
    this.$vs.loading();

    this.$store
      .dispatch(
        "HospitalList/GetHospitalParentSpecialty",
        this.$store.state.AppActiveUser.Hospital.ID
      )
      .then((res) => {
        this.HospitalParentSpecialties = res.data.Data;
        this.$vs.loading.close();
      });
  },
};
</script>

<style >
.Active {
  color: #004477;
  padding: 0px;
  border-radius: 12px;
}
.InActive {
  padding: 0px;
  border-radius: 12px;
}
p {
  font-size: medium;
}
</style>
